import React, { FC } from 'react'
import PageTitle from 'src/components/typography/PageTitle'

interface ILegalPageTitleProps {
  model: {
    seo: {
      title: string
    }
    mobileTitleOverride?: string
    desktopTitleOverride?: string
  }
}

const LegalPageTitle: FC<ILegalPageTitleProps> = ({ model }) => {
  if (model.mobileTitleOverride || model.desktopTitleOverride) {
    return (
      <>
        <div className="hidden lg:block">
          <PageTitle>
            <p>{model.desktopTitleOverride ?? model.seo.title}</p>
          </PageTitle>
        </div>
        <div className="lg:hidden">
          <PageTitle>
            <p>{model.mobileTitleOverride ?? model.seo.title}</p>
          </PageTitle>
        </div>
      </>
    )
  }
  return (
    <PageTitle>
      <p>{model.seo.title}</p>
    </PageTitle>
  )
}

export default LegalPageTitle

import React, { FC } from 'react'
import tw from 'twin.macro'
import styled from 'styled-components'

interface ISectionTitleProps {
  children: string[] | string | JSX.Element
  className?: string
}

export const sectionTitleStyles = tw`mt-7 mb-3 font-bold text-left text-lg text-deepDarkBlue`

const StyledSectionTitle = styled.h3`
  ${sectionTitleStyles}
`

const SectionTitle: FC<ISectionTitleProps> = ({ children, className }) => (
  <StyledSectionTitle className={className}>{children}</StyledSectionTitle>
)

export default SectionTitle

import React, { FC } from 'react'

interface IContentPageContainerProps {
  children: (JSX.Element | JSX.Element[])[] | JSX.Element
  id?: string
  className?: string
  isWide?: boolean
  noPadding?: boolean
}

export const pagePadding = '' //'pt-0 md:pt-24'

const ContentPageContainer: FC<IContentPageContainerProps> = ({
  children,
  id,
  className,
  isWide,
  noPadding,
}) => {
  const containerClass = isWide ? '' : 'container'

  return (
    <div
      className={`
        ${containerClass}
        relative
        ${noPadding ? '' : pagePadding}
        ${className ?? ''}
      `}
      id={id}
    >
      {children}
    </div>
  )
}

export default ContentPageContainer

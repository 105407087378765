// i18next-extract-mark-ns-start cookies

import * as React from 'react'
import { graphql } from 'gatsby'
import Layout from 'src/components/layout'
import { Helmet } from 'react-helmet'
import { CmsHtmlContent } from 'src/components/typography/CmsHtmlContentFormats'
import { StyledListWrapper } from 'src/components/typography/List'
import ContentPageContainer from 'src/components/layout/ContentPageContainer'
import Section from 'src/components/typography/Section'
import Article from 'src/components/typography/Article'
import LegalPageTitle from 'src/components/legal/LegalPageTitle'

const IndexPage = ({ data }) => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>{data.model.seo.title}</title>
    </Helmet>
    <Layout>
      <div className="min-h-screen bg-white text-black">
        <ContentPageContainer>
          <LegalPageTitle model={data.model} />
          <Section>
            <Article>
              <StyledListWrapper>
                <CmsHtmlContent
                  dangerouslySetInnerHTML={{ __html: data.model.content }}
                />
              </StyledListWrapper>
            </Article>
          </Section>
        </ContentPageContainer>
      </div>
    </Layout>
  </>
)

export const query = graphql`
  query($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    model: datoCmsCookiePolicy(locale: { eq: $language }) {
      content
      seo {
        title
      }
      mobileTitleOverride
      desktopTitleOverride
    }
  }
`

export default IndexPage

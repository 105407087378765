import React, { FC } from 'react'
import { TFunctionResult } from 'i18next'
import tw from 'twin.macro'
import styled from 'styled-components'

interface IParagraphProps {
  children?:
    | JSX.Element
    | string
    | TFunctionResult
    | (JSX.Element | string | TFunctionResult)[]
  className?: string
  dangerouslySetInnerHTML?: {
    __html: string
  }
}

export const paragraphStyles = tw`text-lg text-deepDarkBlue tracking-wide`
export const paragraphMargin = tw`mb-5`

export const StyledParagraph = styled.p`
  ${paragraphStyles}
  ${paragraphMargin}
`

const Paragraph: FC<IParagraphProps> = ({
  children,
  className,
  dangerouslySetInnerHTML,
}) => {
  if (dangerouslySetInnerHTML) {
    return (
      <StyledParagraph
        className={className || ''}
        dangerouslySetInnerHTML={dangerouslySetInnerHTML}
      />
    )
  }

  return (
    <StyledParagraph className={className || ''}>{children}</StyledParagraph>
  )
}

export default Paragraph

import React, { FC } from 'react'
import {
  paragraphStyles,
  paragraphMargin,
} from 'src/components/typography/Paragraph'
import { StyledPageTitle } from 'src/components/typography/PageTitle'
import {
  SubtitleIheritableStyles,
  SubtitleStyles,
  SubtitleMargin,
} from 'src/components/typography/Subtitle'
import { sectionTitleStyles } from 'src/components/typography/SectionTitle'
import { StyledListWrapper } from 'src/components/typography/List'
import themeColors from 'src/helpers/theme-colors'
import styled from 'styled-components'
import tw from 'twin.macro'
import tailwindcss from 'tailwindcss'

const BaseCmsHtmlContent = styled.div`
  ${paragraphStyles}
  code:not(em) {
    ${tw`underline font-roag`}
    text-decoration-color: ${themeColors.darkYellow};
    text-decoration-thickness: 2px;
  }
  em:not(code) {
    ${tw`italic`}
  }
  u {
    ${tw`underline`}
    text-decoration-thickness: 2px;
  }
  blockquote {
    ${tw`text-grayBlue pl-4 border-l-4 border-paleBlue`}
    p {
      ${tw`text-grayBlue`}
    }
    ul li {
      &::before {
        ${tw`bg-grayBlue`}
      }
      ul li {
        &::before {
          ${tw`border-grayBlue bg-transparent`}
        }
      }
    }
  }
  a {
    ${tw`text-almostBlackBlue transition duration-300`}
    text-decoration: underline 2px ${themeColors.appGreen};
    text-underline-offset: 4px;

    &:hover{
      ${tw`bg-appLightGreen`}
    }
  }
  img {
    ${tw`mt-12 mb-12 w-full`}
  }
  p {
    ${paragraphStyles}
    ${paragraphMargin}
    &:last-child {
      ${tw`/*mb-0*/`}
      img {
        ${tw`mb-0`}
      }
    }
  }
  h1 {
    ${StyledPageTitle}
  }
  h2 {
    ${SubtitleIheritableStyles}
    ${SubtitleStyles}
    ${SubtitleMargin}
  }
  h3 {
    ${sectionTitleStyles}
    &:first-child {
      ${tw`mt-0`}
    }
  }
  h4 {
    ${tw`font-bold text-lg tracking-normal`}
  }
  h5 {
    ${tw`text-sm font-bold`}
  }
  h6 {
    ${tw`text-xs font-bold`}
  }
  td,
  th {
    ${tw`border p-2`}
  }
`

export const CmsHtmlContent = styled(BaseCmsHtmlContent)`
  code em,
  em code {
    ${tw`underline not-italic`}
    text-decoration-thickness: 2px;
  }
`

export const CmsHtmlContentGrayUnderline = styled(BaseCmsHtmlContent)`
  code em,
  em code {
    ${tw`underline not-italic`}
    text-decoration-thickness: 2px;
    text-decoration-color: ${themeColors.navyBlueGray};
  }
`

interface IStyledHTMLWrapperProps {
  children: JSX.Element | JSX.Element[] | string
}

export const StyledHtmlContent: FC<IStyledHTMLWrapperProps> = ({
  children,
}) => (
  <CmsHtmlContent>
    <StyledListWrapper>{children}</StyledListWrapper>
  </CmsHtmlContent>
)

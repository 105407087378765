import React, { FC } from 'react'
import styled, { CSSProperties } from 'styled-components'
import tw from 'twin.macro'

interface ISubtitleProps {
  children: string | String | JSX.Element | JSX.Element[] | (string | number)[]
  style?: CSSProperties
  className?: string
  noMargin?: boolean
}

interface IStyledSubtitleProps {
  noMargin: boolean
}

export const SubtitleIheritableStyles = tw`
  text-center text-deepDarkBlue tracking-wider block w-full text-lg font-bold text-left
  lg:text-4xl lg:font-light lg:text-center
`

export const SubtitleStyles = tw`
  border-t-2 border-b-2 border-deepDarkBlue py-2 
  lg:py-3
`

export const SubtitleMargin = tw`mb-7`

const StyledSubtitle = styled.h2<IStyledSubtitleProps>`
  ${SubtitleIheritableStyles}
  ${SubtitleStyles}
  ${({ noMargin }) => (noMargin ? '' : SubtitleMargin)}
  button {
    ${SubtitleIheritableStyles}
  }
`

const Subtitle: FC<ISubtitleProps> = ({
  children,
  style,
  className,
  noMargin,
}) => (
  <StyledSubtitle style={style} className={className} noMargin={noMargin}>
    {children}
  </StyledSubtitle>
)

export default Subtitle

import React, { FC } from 'react'

interface IArticleProps {
  noMarginMobile?: boolean
  noMarginDesktop?: boolean
  id?: string
  className?: string
}

const Article: FC<IArticleProps> = ({
  children,
  noMarginMobile,
  id,
  className,
  noMarginDesktop,
}) => (
  <article
    id={id}
    className={`${className || ''}
    ${noMarginMobile ? '' : 'mb-14'}
    ${noMarginDesktop ? '' : 'lg:mb-14'}
    text-left`}
  >
    {children}
  </article>
)

Article.defaultProps = {
  noMarginMobile: false,
  noMarginDesktop: false,
}

export default Article
